import React from 'react'
import style from '../../styles/Heading.module.css'

function Locationheading() {
  return (
    <div className={style.Heading}>
      <h4>LOCATION</h4>
      <p>오시는 실</p>
    </div>
  )
}

export default Locationheading