import React from 'react'
import SpecialBody from './SpecialBody'
import Blank from '../Blank'
import SpecialHeading from './SpecialHeading'

function SpecialMain() {
  return (
    <div>
      <Blank />
      <SpecialHeading />
      <SpecialBody />
      <Blank />
    </div>
  )
}

export default SpecialMain