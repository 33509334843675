
import React from 'react';
import style from '../../styles/Heading.module.css';

function RoomsHeading() {
  return (
    <div className={style.Heading}>
      <h4>ROOM</h4>
      <p>객실 안내</p>
    </div>
  );
}

export default RoomsHeading;


