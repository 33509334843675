import React, { useState } from 'react';
import style from '../../styles/RefundPolicy.module.css'; // 스타일링을 위한 CSS 파일 import

function RefundPolicy() {

  return (
    <div className={style.container}>
      <h3>환불 규정 및 이용 안내</h3>
      <div className={style.policyContent}>
        <p>* 펜션의 취소수수료는 이용 일자별로 적용되어 합산됩니다. (단, 옵션 상품의 경우 이용 첫째 날 기준으로 적용됩니다.)</p>
        <p>* 이용 당일 예약 후 바로 취소한 경우에도 이용 당일 취소이므로 환불이 불가합니다.</p>
        <table className={style.policyTable}>
          <thead>
            <tr>
              <th>취소일기준</th>
              <th>취소수수료</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>기본 취소 수수료</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>예약 7일 후부터 이용 6일 전</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>예약 8일 후부터 이용 5일 전</td>
              <td>10%</td>
            </tr>
            <tr>
              <td>이용 4일 전</td>
              <td>20%</td>
            </tr>
            <tr>
              <td>이용 3일 전</td>
              <td>30%</td>
            </tr>
            <tr>
              <td>이용 2일 전</td>
              <td>50%</td>
            </tr>
            <tr>
              <td>이용 1일 전</td>
              <td>70%</td>
            </tr>
            <tr>
              <td>이용일 당일</td>
              <td>100% (환불불가)</td>
            </tr>
          </tbody>
        </table>
        <p>* 환불은 결제한 금액에서 취소수수료를 제외한 금액으로 환불됩니다. (취소수수료는 마일리지, 쿠폰, 특가 적용(할인) 전 이용금액 기준으로 책정됩니다.)</p>
        <p>* 예약 후 펜션이나 객실 변경은 해당 예약 취소 후 다시 예약하셔야 합니다.</p>
        <p>* 취소수수료는 결제금액이 아닌 예약금액(객실금액+옵션금액) 기준으로 책정됩니다.</p>
        <p>* 숙박 업소는 법적으로 청소년 혼숙이 금지되어 있습니다. 또한 미성년자의 예약 및 이용은 숙소 규정에 따라 결정되며 해당 사유로 환불 받을 수 없습니다.</p>
        <p>* 최대 인원 초과로 인한 입실 거부 시 환불되지 않으니 유의하시기 바랍니다.</p>
        <p>* 사전 동의 없는 반려동물 입실은 불가능합니다. 반려동물 입실 가능 펜션 외에 반려동물 동반 시 입실이 거부될 수 있으며, 해당 사유로 환불 받을 수 없습니다.</p>
        <p>* 예약 변경을 위한 취소 시에도 취소수수료가 부과되오니 양지하여 주시기 바랍니다.</p>
        <p>* 실시간예약 특성상 1객실에 중복예약이 발생될 수 있으며, 이 경우 먼저 결제된 예약 건에 우선권이 있습니다.</p>
        <p>* 펜션별 기간이 미 확정되어 요금이 잘못 반영되었거나 요금표가 잘못 등록된 경우 예약이 완료된 경우라도 예약이 취소되며, 정상 금액으로 재 예약할 수 있습니다.</p>
        <p>* 정상적으로 예약이 완료되면 문자(카카오톡)로 안내해 드리며, 펜션 연락처도 함께 발송됩니다.</p>
        <p>* 휴대폰번호가 잘못 입력되어 연락이 불가할 경우 발생되는 불이익은 고객 책임이오니 예약 시 입력한 정보를 꼭 확인해주세요.</p>
        <p>* 기타 문의 사항은 010-3599-9573 | 010-4035-9573 으로 문자 부탁드립니다.</p>
      </div>

    </div>
  );
}

export default RefundPolicy;
